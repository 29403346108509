<template>
  <div>

    <div style="position: absolute; width: 90%; top: 20px;right: 5%; text-align: right;">
      <select v-model="curPose" id="rolePose" style="padding: 5px 8px; border: 1px solid #ccc;">
        <option value="idle" >站立</option>
        <option value="hi" >Hi</option>
      </select>
    </div>

    <div style="position: absolute; width: 90%; height: 150px; bottom: 20px; background-color: white; border-radius: 8px; box-shadow: 5px 5px 20px; right: 5%; text-align: center;">
      <div style="width: 90%; margin: 20px auto;">
        <textarea  v-model="textareaValue" style="width: 100%; border: 1px solid #ccc;" name="" id="content" cols="30" rows="3" placeholder="say something."></textarea>
      </div>
      <div style="text-align: left;width: 90%; margin: 20px auto;">
        <button @click="sendSpeak" style="background-color: #fff; border: 1px solid #ccc;padding: 5px 8px;" id="say">说话</button>
      </div>

      <audio ref="speakAudio" :src="speakAudioSrc" preload="auto"></audio>
    </div>    
  </div>
</template>


<script>
import { reactive, ref } from 'vue';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

//import TWEEN from '@tweenjs/tween.js'

export default {
  name: 'App',
  setup() {
    const curPose = ref('idle');

    const speakAudio    = ref(null);
    const speakAudioSrc = ref('');

    function updateSpeakSrc (newSrc) {
      speakAudioSrc.value = newSrc
    }

    const state = reactive({
      newMorphArray: [],
      morphArray: [],
      actions: {}
    });
    const textareaValue = ref('');
    function cleanTextarea() {
      textareaValue.value = ""
    }

    function getMorphItem() {
      return state.morphArray.shift()
    }
    
    function setNewMorphItems(newItems) {
      state.newMorphArray = newItems
    }
    function setMorphItems() {
      state.morphArray = state.newMorphArray
    }

    function setActions(name, act) {
      state.actions[name] = act
    }
    function getActions(name) {
      return state.actions[name]
    }

    // async function sendSpeak(){
    //   if (!this.textareaValue){
    //     return;
    //   }
      
    //   // 发起请求 转化语音 获取动画
    //   alert(this.textareaValue)
    //   this.sendPostSpeak(this.textareaValue)
    //   textareaValue.value = ""
    // }

    return {
      curPose,
      speakAudio,
      speakAudioSrc,
      updateSpeakSrc,
      textareaValue,
      cleanTextarea,
      state,
      setNewMorphItems,
      getMorphItem,
      setMorphItems,
      setActions,
      getActions,
    };
  },
  watch: {
    curPose: {
      handler(newPose, oldPose) {
        this.changePose(newPose, oldPose)
      },
      immediate: true, // 可选，是否在初次渲染时立即执行一次
    },
  },
  methods: {
    changePose(newPose, oldPose){
      if (newPose && !oldPose) {
        return;
      }
      let fromAction = this.getActions(oldPose)
      let toAction = this.getActions(newPose)
      
      fromAction.fadeOut(2)
      toAction
        .reset()
        .setEffectiveTimeScale( 1 )
        .setEffectiveWeight( 1 )
        .fadeIn( 2 )
        .play();
    },
    async sendSpeak() {
      
      const params = {
        content: this.textareaValue
      };
      const url = 'http://character.metabox.ren/api/pose';
      const headers = {
        'Content-Type': 'application/json'
      };

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers,
          body: JSON.stringify(params)
        });

        if (!response.ok) { // status !== 200-299
          throw new Error('请求失败');
        }

        const data = await response.json();

        if (data.code === 200) {
          this.setNewMorphItems(data.data.frames)
          this.updateSpeakSrc("http://character.metabox.ren/public/" + data.data.file)
        } else if (data.code === 400) {
          alert(data.message)
        } else {
          alert(`未知状态码：${data.code}`);
        }
      } catch (error) {
        alert('请求过程中发生错误:', error.message);
      }
      this.cleanTextarea()
    }
  },
  mounted() {
    let _this = this

    const audioElement = this.speakAudio;
    audioElement.addEventListener('canplay', () => {
        console.log('Background music loaded successfully.');

        // 音频加载完毕，可以播放动画
        this.setMorphItems()
        audioElement.play();
    });

    const scene = new THREE.Scene();
    scene.background = new THREE.Color( 0xa0a0a0 );
		scene.fog = new THREE.Fog( 0xa0a0a0, 10, 100 );


    const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.set(0, 1.7, 1.5);
    // camera.lookAt(0, 4, 0)


    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.append(renderer.domElement);

    // Add ambient light to the scene
    const ambientLight = new THREE.AmbientLight(0xffffff, 2);
    scene.add(ambientLight);


    const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x8d8d8d, 0.1 );
    hemiLight.position.set( 0, 20, 10 );
    scene.add( hemiLight );
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(15, 10, 10);
    scene.add(directionalLight);


    const mesh = new THREE.Mesh( new THREE.PlaneGeometry( 2000, 2000 ), new THREE.MeshPhongMaterial( { color: 0xcbcbcb, depthWrite: false } ) );
		mesh.rotation.x = - Math.PI / 2;
    scene.add( mesh );

    
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.target.set(0, 1.4, 0)
    controls.autoRotate = false;
    controls.autoRotateSpeed = 0.2;



    // Load and render public/guangxingli.glb
    const loader = new GLTFLoader();
    loader.load('riged_merge_idle_hello_1.glb', function (gltf) {

      console.log(gltf)
      
      scene.add(gltf.scene);
      const animations = gltf.animations;

      // 创建动画控制器
      const mixer = new THREE.AnimationMixer(scene);

      animations.forEach(clip => {
          const action = mixer.clipAction(clip);
          if (clip.name == "Armature|mixamo.com|Layer0_remap") {

            _this.setActions('idle', action)
            action.play();
          }
          if (clip.name == "Armature.001|mixamo.com|Layer0_remap") {
            _this.setActions('hi', action)
          }
          
      });

      // 获取面部
      const headMesh = gltf.scene.getObjectByName('Wolf3D_Head'); // 
      const morphTargetNames = Object.keys(headMesh.morphTargetDictionary);
      const eyeLeftMesh   = gltf.scene.getObjectByName('EyeLeft'); // 
      const eyeRightMesh  = gltf.scene.getObjectByName('EyeRight'); // 
      const teethMesh  = gltf.scene.getObjectByName('Wolf3D_Teeth'); // 
      
      // 确保形态键数量与您的数组元素数量一致
      if (morphTargetNames.length !== 52) {
        console.warn('Number of morph targets does not match expected count of 52.');
      }

      function updateMorphTargets(newMorphTargetValuesArray) {
        for (let i = 0; i < 52; i++) {
          const weight = newMorphTargetValuesArray[i];
          // console.log(newMorphTargetValuesArray)
          headMesh.morphTargetInfluences[i] = weight;
          eyeLeftMesh.morphTargetInfluences[i] = weight;
          eyeRightMesh.morphTargetInfluences[i] = weight;
          teethMesh.morphTargetInfluences[i] = weight;
        }
        headMesh.needsUpdate = true
        eyeLeftMesh.needsUpdate = true
        eyeRightMesh.needsUpdate = true
        teethMesh.needsUpdate = true
      }


     
      // 在每帧更新动画
      const clock = new THREE.Clock();
      function animate() {
        requestAnimationFrame(animate);

        let arrItem = _this.getMorphItem()
        if (arrItem) {
          // console.log(arrItem)
          updateMorphTargets(arrItem)
        }


        mixer.update(clock.getDelta()); // clock 是 THREE.Clock 实例，用于计算时间流逝

        controls.update();

        renderer.render(scene, camera);
      }
      animate();


  }, undefined, function (error) {
    console.error(error);
  });

  
  }
}
</script>



<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>